import React from 'react'
import './modern.css';
import sideone from '../../assets/side_image_1.png'
import Spacing from '../Spacing';
import sidetwo from '../../assets/side_image_2.png'
import arrow from '../../assets/white-arrow.png'
import sideth from '../../assets/side_image_3.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const Modern = () => {
    return (
        <>
        <div className='vert-cale_line_parent'>
        <div className='vert-cale_line_1'> </div>
        <div className='vert-cale_line_2'> </div>
        <div className='vert-cale_line_3'> </div>
            <div className='modern-interior-service-parent-div'>
                <Spacing lg="190" md="80" />

                <div className='modern-interior-service-right-side'>
                    <div className='right_modern_image'>
                       
                    </div>
                   
                    <div className='modern-interior-service-left-side'>
                        <div className='modern-interior-h1'>
                            
                            <h1>  Modern Interior</h1>
                            <h2>Design Service</h2> 
                            <Spacing lg="130" md="80" />
                            <p> Whether you're embarking on a full-scale home renovation or simply seeking small yet impactful changes, we're here to guide you through every step of the design process.</p>
                        </div>
                        {/* <div className='btn_explore_right_img'>
                            <div className="btn-explore  btn-explore-now">
                                <div className='btn-text '>Explore Now</div>
                                <img className='btn-arrow' src={arrow} alt="" />
                            </div>
                        </div> */}
                            <Spacing lg="0" md="130" />
                            <Spacing lg="0" md="80" />
                        <div className='make_your_dream_div_parent'>
                        <div className='make_your_dream_div'>
                                    <p>Let’s Create Something Beautiful Together</p>
                                </div>
                            <InputGroup className="make_your_dream_parent ">
                               
                                <Form.Control
                                    placeholder="Your email address"
                                    className='form_chat_main custom-placeholder no-border'
                                />
                                <Button variant="outline-secondary" className='lets_chat_btn no-hover'>
                                    Let’s Chat
                                </Button>

                            </InputGroup>
                            </div>

                    </div>

                </div>
                {/* <Spacing lg="130" md="80" /> */}
            </div>
            <div className='horizontal_line_1'>

            </div>
            <div className=''>
                <div className='container'>
                    <div className='row reverse_row_images'>
                    <div className='col-md-6 col-lg-6 col-xl-6'>
                            <div className='Services-We-Provide'>
                                <h1> Services We Provide</h1>
                                <div className='content_right_side_service'>
                                    <span>1</span>
                                    <div className='content_right_side_service_inner one_inner'>
                                        <h4>Residential Design</h4>
                                        <p>Tailored design solutions to create homes that reflect your lifestyle</p>

                                    </div>
                                </div>

                                <div className='content_right_side_service'>
                                    <span>2</span>
                                    <div className='content_right_side_service_inner'>
                                        <h4>Renovations</h4>
                                        <p>Revitalise existing spaces with thoughtful renovations</p>

                                    </div>
                                </div>
                                <div className='content_right_side_service'>
                                    <span>3</span>
                                    <div className='content_right_side_service_inner'>
                                        <h4>Commercial Projects</h4>
                                        <p>Blending functionality with aesthetic appeal to enhance productivity</p>

                                    </div>
                                </div>
                                <div className='content_right_side_service content_desktop_none'>
                                    <span>4</span>
                                    <div className='content_right_side_service_inner'>
                                        <h4>Consultations</h4>
                                        <p>Personalized consultations to understand your vision, preferences, and budget.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-6 col-xl-6 '>
                            <div className='side_images_parent'>
                            <img src={sidetwo} alt="image" className='side_two' />
                                <img src={sideone} alt="image" className='side_one' />
                               
                            </div>
                            <div className='side_image_3'>
                                <img src={sideth} alt="image" className='side_3' />
                            </div>

                           
                            <div className='content_right_side_service content_mobile_none'>
                                    <span>4</span>
                                    <div className='content_right_side_service_inner'>
                                        <h4>Consultations</h4>
                                        <p>Personalized consultations to understand your vision, preferences, and budget.</p>

                                    </div>
                                </div>
                        </div>
                  
                    
                    </div>

                </div>
            </div>
            <Spacing lg="60" md="80" />
            <div className='border_modern_interior'>

            </div>
            </div>
            

        </>
    )
}

export default Modern;
