import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Modern from "./modern";
import Spacing from "./Spacing";
import CreateSpace from "./createSpace";
import Provide from "./providing";
import Working from "./working";
import Designs from "./Designs";
import Footer from "./Footer";
import "./about.css";
// import Footer from './Footer';
// Animation variants for framer-motion
const fadeInUp = {
  hidden: { opacity: 0, y: 40, scale: 0.95 },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.7, ease: "easeOut" },
  },
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { staggerChildren: 0.3 },
  },
};

const parallaxEffect = {
  hidden: { y: 0 },
  visible: {
    y: [0, -50, -100], // Parallax effect as the user scrolls down
    transition: { duration: 1.5, ease: "easeOut" },
  },
};
const About = () => {
  const useInView = (options) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Trigger the animation when in view
        } else {
          // setIsVisible(false); 
        }
      }, options);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => observer.disconnect();
    }, [options]);

    return [ref, isVisible];
  };

  // Using the hook for each section
  const [modernRef, modernInView] = useInView({ threshold: 0.3 });
  const [provideRef, provideInView] = useInView({ threshold: 0.3 });
  const [designsRef, designsInView] = useInView({ threshold: 0.3 });
  const [workingRef, workingInView] = useInView({ threshold: 0.3 });

  return (
    <>
      <Header />
      <Spacing lg="130" md="120" />
      <Spacing lg="30" md="40" />
      {/* <Spacing lg="130" md="80" /> */}

      <div className="container side-container-borders">
        <motion.div
          ref={modernRef}
          initial="hidden"
          animate={modernInView ? "visible" : "hidden"}
          variants={fadeInUp}
        >
          <div className="meet-hcube">
            <div className="row">
              <div className="meet-hcube-heading">
                <h4>Meet HCube Studio : </h4>
                <h1>Where Vision Meets Reality</h1>
                <p>
                  HCube Studio provide you design and build turnkey solution,
                  with a specialization in execution. Hiring a Designer directly
                  will save both cost and time, we offer complete transparency,
                  flexibility, reliability and 100 % commitment. Our team is
                  dedicated, experienced and execute projects as per design.
                </p>
              </div>
            </div>
          </div>
        </motion.div>

        <Spacing lg="180" md="130" />
        <motion.div
          initial="hidden"
          animate="visible"
          variants={parallaxEffect}
          className="parent-container"
        >
          <div class="parent-container">
            <div class="background-text">VISION</div>
            <div class="overlay-box">
              <p>
                HCube Interior Design studio is a Team of Young Professionals
                With an Aim To be The Most Trusted Interiors Fit-out Contractors
                and Providing Best and Fast Interiors Solutions
              </p>
            </div>
          </div>
        </motion.div>
        <Spacing lg="130" md="100" />

        <motion.div
          initial="hidden"
          animate="visible"
          variants={parallaxEffect}
          className="parent-container-2"
        >
          <div class="parent-container-2">
            <div class="background-text-2">MISSION</div>
            <div class="overlay-box">
              <p>
                Providing Simple , Quick and Hassle Free Interiors Fit Out
                Solutions to Our Clients
              </p>
            </div>
          </div>
        </motion.div>
        <div className="custom-container">
         
            <div className="row zigzag-row">
              <Spacing lg="100" md="140" />
              <div className="col-left" >
                <div className=" box-title">
                  {" "}
                  <h1>Design Philosophy</h1>
                </div>
                </div>
                <div className="col-right" >
                <div className="box">
                  <h3>Functionality</h3>
                  <p>
                    Each element of our designs serves a purpose, maximizing
                    efficiency and enhancing the overall functionality of the
                    space.
                  </p>
                </div>
                </div>
            </div>
            <div className="row zigzag-row" >
              <div className="col-left pt-3">
                <div className="box">
                  <h3>Innovation</h3>
                  <p>
                    By staying abreast of the latest trends, technologies, and
                    materials, we infuse our projects with cutting-edge design
                    elements that set them apart
                  </p>
                </div>
              </div>
              <div className="col-right">
                <div className="box">
                  <h3>Sustainability</h3>
                  <p>
                    We strive to minimize our environmental footprint while
                    creating spaces that are healthy and environmentally
                    friendly
                  </p>
                </div>
              </div>
              </div>



            
            <div className="row zigzag-row">
              <div className="col-left pt-3">
                <div className="box">
                  <h3>Collaboration</h3>
                  <p>
                    we co-create spaces that truly reflect their personality,
                    lifestyle, and aspirations
                  </p>
                </div>
              </div>
              <div className="col-right">
                <div className=" box-title">
                  {" "}
                  <h1>Design Philosophy</h1>
                </div>
              </div>
            </div>
            <div className="row zigzag-row" >
              <div className="col-left pt-3">
                <div className="box">
                  <h3>Timelessness</h3>
                  <p>
                    We design with longevity in mind, creating spaces that stand
                    the test of time both in terms of aesthetics and
                    functionality.
                  </p>
                </div>
              </div>
              <div className="col-right">
                <div className="box">
                  <h3>Empathy</h3>
                  <p>
                    We understand the profound impact that well-designed spaces
                    can have on the emotions and well-being of their occupants
                  </p>
                </div>
              </div>
              </div>
            <div className="row zigzag-row pt-3">
            <div className="col-left" >
             
                <div className="box">
                  <h3>Detail</h3>
                  <p>
                    We believe that beauty lies in the details, and we
                    meticulously attend to every aspect of our designs, no
                    matter how small.
                  </p>
                </div>
                </div>
              <div className="col-right"></div>
            </div>
        
        </div>
      </div>

      <Spacing lg="150" md="100" />
      {/* <Spacing lg="190" md="100" /> */}
      <motion.div
        ref={workingRef}
        initial="hidden"
        animate={workingInView ? "visible" : "hidden"}
        variants={fadeInUp}
      >
        <Working />
      </motion.div>
      <Spacing lg="150" md="100" />
      <Footer />
    </>
  );
};
export default About;
