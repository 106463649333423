import React from 'react';
import './work.css';
import right from '../../assets/work-right.png'
import arrow from '../../assets/white-arrow.svg'
import arw from '../../assets/arrow.svg'
import Spacing from '../Spacing';

const Working = () => {
    return (
        <>
            <div className='working_with_container'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 col-lg-8 col-xl-8 col-6 col-sm-6'>
                              <Spacing lg="50" md="0" />
                            <div className='Section-Title'>
                                <h1>Interested in</h1>
                                <h1>Working with Us?</h1>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown</p>
                            </div>
                            <div className="parent_div_btn_arrow">
                            <div className="btn-explore">
                                <div className='btn-text'>Explore</div>
                                <img className='btn-arrow' src={arrow} alt="" />
                            </div>
                            <div className='right_arrow_black'>
                                <div className=''>
                                <img className='btn-arrow-black' src={arw} alt="" />
                                </div>

                            </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-lg-4 col-xl-4 col-6 col-sm-6 '>
                            <div className='working_right_image'>
                                <img src={right} alt="" />

                            </div>
                        </div>
                    </div>
                </div>
                <Spacing lg="150" md="80" />
            </div>

        </>
    )
}

export default Working
