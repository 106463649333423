import React from 'react'
import Header from './Header'
import Modern from './modern'
import Spacing from './Spacing';
import CreateSpace from './createSpace';
import Provide from './providing';
import Working from './working';
import Designs from'./Designs';
import Footer from './Footer';
// import Footer from './Footer';

export default function ServicePage() {
  return (
  <>
  <Header />
  <Spacing lg="130" md="80" />
  
  <Spacing lg="0" md="50" />
  <Modern />
  <Spacing lg="0" md="20" />
  <CreateSpace />

  <Spacing lg="150" md="0" />
  <Spacing lg="150" md="0" />
  <Spacing lg="190" md="0" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />
  <Spacing lg="0" md="150" />


  <Provide />
  
    <Designs />
  <Spacing lg="150" md="100" />
  {/* <Spacing lg="190" md="100" /> */}
  <Working />
  <Spacing lg="150" md="100" />
  <Footer />


  </>
  )
}
