import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from '../assets/logo.png'

import { NavLink } from "react-router-dom";

import { useMediaPredicate } from "react-media-hook";


function Header({
  hdrop,
  hdropa,
  showDarkLogo,
  scrollColor,
  burgerColor,
  logincolor,
}) {
  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 10) {
      setColorchange(true);
    } else if (window.scrollY <= 10) {
      setColorchange(false);
    }
  };


  const [burger1, setburger1] = useState(false);

  function burger_click() {
    setburger1(!burger1);
  }





  window.addEventListener("scroll", changeNavbarColor);

  function Toptoscroll() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  const moreThane1100 = useMediaPredicate("(max-width:1100px)");

  return (
    <>
      <div
        className={
          colorChange
            ? "header-container header-container-active"
            : "header-container"
        }
        style={{ backgroundColor: colorChange ? scrollColor : null }}
      >
        <div className="header-container-parent">
          {showDarkLogo ? (
            <NavLink onClick={Toptoscroll} to="/">
              <img src={logo} alt="" className="sports-logo none1-logo  " />
            </NavLink>
          ) : (
            <NavLink onClick={Toptoscroll} to="/">
              <img src={logo} alt="" className="sports-logo none1-logo logo" />
            </NavLink>
          )}
          <NavLink onClick={Toptoscroll} to="/">
            <img src={logo} alt="" className="sports-logo none2-logo  " />
          </NavLink>
          <div
            className={
              burger1 ? "header-nav" : "header-nav header-nav-active"
            }
            style={{
              backgroundColor: moreThane1100 ? scrollColor : ""
            }}
          >
            <NavLink
              onClick={Toptoscroll}
              to="/"
              className=" responsive-header-navlinks links_nav_text"
            >
              Home
            </NavLink>



            <NavLink
              onClick={Toptoscroll}
              to="/About"
              className="responsive-header-navlinks links_nav_text"
            >
              About
            </NavLink>



        

            <div className="btn-parent mediam_screen">
              <NavLink onClick={Toptoscroll} to="/Contact">
                <button
                  className=" header-download links_nav_text"

                >

                  Contact Us
                </button>
              </NavLink>
            </div>
          </div>

          <div className="burger-menu" onClick={burger_click}>
            {burger1 ? (
              <FaTimes
                className="fatb"
                style={{ fontSize: "30px", color: burgerColor }}
              />
            ) : (
              <FaBars
                className="fatb"
                style={{ fontSize: "30px", color: burgerColor }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
