import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Header from "./Header";
import Modern from "./modern";
import Spacing from "./Spacing";
import CreateSpace from "./createSpace";
import Provide from "./providing";
import Working from "./working";
import Designs from "./Designs";
import Footer from "./Footer";
// import Footer from './Footer';
const smoothFadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
  },
};
const smoothFadeInLeft = {
  hidden: { opacity: 0, x: -50 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
  },
};
const smoothFadeInRight = {
  hidden: { opacity: 0, x: 50 },
  visible: {
    opacity: 0.9,
    x: 0,
    transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
  },
};

const Home = () => {
  const useInView = (options) => {
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(ref.current); // Stop observing once it's in view
        }
      }, options);

      if (ref.current) {
        observer.observe(ref.current);
      }

      return () => observer.disconnect();
    }, [options]);

    return [ref, isVisible];
  };

  // Using the hook for each section
  const [modernRef, modernInView] = useInView({ threshold: 0.2 });
  const [provideRef, provideInView] = useInView({ threshold: 0.2 });
  const [designsRef, designsInView] = useInView({ threshold: 0.2 });
  const [workingRef, workingInView] = useInView({ threshold: 0.2 });

  return (
    <>
      <Header />
      <Spacing lg="130" md="80" />

      <Spacing lg="0" md="50" />
      {/* Modern Section with smooth fade-in-up animation */}
      <motion.div
        ref={modernRef}
        initial="hidden"
        animate={modernInView ? "visible" : "hidden"}
        variants={smoothFadeInUp}
      >
        <Modern />
      </motion.div>
      <Spacing lg="0" md="20" />
      {/* <CreateSpace /> */}

      {/* <Spacing lg="150" md="0" />
  <Spacing lg="150" md="0" /> */}
      <Spacing lg="100" md="0" />
      

      <motion.div
        ref={provideRef}
        initial="hidden"
        animate={provideInView ? "visible" : "hidden"}
        variants={smoothFadeInLeft}
      >
        <Provide />
      </motion.div>
      <motion.div
        ref={designsRef}
        initial="hidden"
        animate={designsInView ? "visible" : "hidden"}
        variants={smoothFadeInRight}
      >
        <Designs />
      </motion.div>
      <Spacing lg="150" md="100" />
      {/* <Spacing lg="190" md="100" /> */}
      <motion.div
        ref={workingRef}
        initial="hidden"
        animate={workingInView ? "visible" : "hidden"}
        variants={smoothFadeInUp}
      >
        <Working />
      </motion.div>
      <Spacing lg="150" md="100" />
      <Footer />
    </>
  );
};
export default Home;










// import React, { useEffect, useRef, useState } from "react";
// import { motion } from "framer-motion";
// import Header from "./Header";
// import Modern from "./modern";
// import Spacing from "./Spacing";
// import CreateSpace from "./createSpace";
// import Provide from "./providing";
// import Working from "./working";
// import Designs from "./Designs";
// import Footer from "./Footer";
// // import Footer from './Footer';
// const smoothFadeInUp = {
//   hidden: { opacity: 0, y: 50 },
//   visible: {
//     opacity: 1,
//     y: 0,
//     transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
//   },
// };
// const smoothFadeInLeft = {
//   hidden: { opacity: 0, x: -50 },
//   visible: {
//     opacity: 1,
//     x: 0,
//     transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
//   },
// };
// const smoothFadeInRight = {
//   hidden: { opacity: 0, x: 50 },
//   visible: {
//     opacity: 0.9,
//     x: 0,
//     transition: { type: "spring", stiffness: 50, damping: 20, duration: 1 },
//   },
// };

// const Home = () => {
//   const useInView = (options) => {
//     const [isVisible, setIsVisible] = useState(false);
//     const ref = useRef(null);

//     useEffect(() => {
//       const observer = new IntersectionObserver(([entry]) => {
//         setIsVisible(entry.isIntersecting); // Set visibility based on whether the element is in view
//       }, options);

//       if (ref.current) {
//         observer.observe(ref.current);
//       }

//       return () => observer.disconnect();
//     }, [options]);

//     return [ref, isVisible];
//   };

//   // Using the hook for each section
//   const [modernRef, modernInView] = useInView({ threshold: 0.2 });
//   const [provideRef, provideInView] = useInView({ threshold: 0.2 });
//   const [designsRef, designsInView] = useInView({ threshold: 0.2 });
//   const [workingRef, workingInView] = useInView({ threshold: 0.2 });

//   return (
//     <>
//       <Header />
//       <Spacing lg="130" md="80" />

//       <Spacing lg="0" md="50" />
//       {/* Modern Section with smooth fade-in-up animation */}
//       <motion.div
//         ref={modernRef}
//         initial="hidden"
//         animate={modernInView ? "visible" : "hidden"}
//         variants={smoothFadeInUp}
//       >
//         <Modern />
//       </motion.div>
//       <Spacing lg="0" md="20" />
//       {/* <CreateSpace /> */}

//       {/* <Spacing lg="150" md="0" />
//   <Spacing lg="150" md="0" /> */}
//       <Spacing lg="100" md="0" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />
//       <Spacing lg="0" md="150" />

//       <motion.div
//         ref={provideRef}
//         initial="hidden"
//         animate={provideInView ? "visible" : "hidden"}
//         variants={smoothFadeInLeft}
//       >
//         <Provide />
//       </motion.div>
//       <motion.div
//         ref={designsRef}
//         initial="hidden"
//         animate={designsInView ? "visible" : "hidden"}
//         variants={smoothFadeInRight}
//       >
//         <Designs />
//       </motion.div>
//       <Spacing lg="150" md="100" />
//       {/* <Spacing lg="190" md="100" /> */}
//       <motion.div
//         ref={workingRef}
//         initial="hidden"
//         animate={workingInView ? "visible" : "hidden"}
//         variants={smoothFadeInUp}
//       >
//         <Working />
//       </motion.div>
//       <Spacing lg="150" md="100" />
//       <Footer />
//     </>
//   );
// };
// export default Home;

