import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Form from 'react-bootstrap/Form';
import Spacing from '../Spacing';
import Footer from '../Footer';
import { useForm, ValidationError } from '@formspree/react';
import ic from '../../assets/icon-1.png';
import ico from '../../assets/icon-2.png';
import icon from '../../assets/icon-3.png'
import insta from '../../assets/insta.png'
import face from '../../assets/face.png'
import './contact.css';

export default function Contact() {
  const [state, handleSubmit] = useForm("mzbnqnjk");
  const [submitted, setSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    referredBy: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await handleSubmit(event);
      if (result.succeeded) {
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          setFormData({
            fullName: '',
            email: '',
            phoneNumber: '',
            referredBy: '',
            message: ''
          });
        }, 1000); // Clear form and hide message after 1 second
      }
    } catch (error) {
      console.error("Form submission error:", error);
    }
  };

  useEffect(() => {
    if (state.succeeded) {
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
        setFormData({
          fullName: '',
          email: '',
          phoneNumber: '',
          referredBy: '',
          message: ''
        });
      }, 1000); // Clear form and hide message after 1 second
    }
  }, [state.succeeded]);

  return (
    <>
      <Header />
      <Spacing lg="130" md="80" />
      <Spacing lg="50" md="80" />
      <div className='container'>
        <div className='get_a_quote_head'>
          <h1>Get a Quote from us</h1>
        </div>
        <div className='get_a_qoute_border'>

        </div>
        <Spacing lg="50" md="80" />
        <div className='row'>
          <div className='col-md-8 col-lg-7 col-xl-8 col-12 col-sm-12'>
            <Form className='form_bg' onSubmit={handleFormSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-1" controlId="exampleForm.ControlInput1">
                    <Form.Label className='fullname'>Full Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="fullName"
                      placeholder="Name"
                      className='input_box_size no-border'
                      value={formData.fullName}
                      onChange={handleChange}
                      required
                    />
                    <ValidationError prefix="Full Name" field="fullName" errors={state.errors} />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-1" controlId="exampleForm.ControlInput2">
                    <Form.Label className='fullname'>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="name@example.com"
                      className='input_box_size no-border'
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Form.Group className="mb-1" controlId="exampleForm.ControlInput3">
                    <Form.Label className='fullname'>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      className='input_box_size no-border'
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      required
                    />
                    <ValidationError prefix="Phone Number" field="phoneNumber" errors={state.errors} />
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-1" controlId="exampleForm.ControlInput4">
                    <Form.Label className='fullname'>Referred By</Form.Label>
                    <Form.Control
                      type="text"
                      name="referredBy"
                      placeholder="name"
                      className='input_box_size no-border'
                      value={formData.referredBy}
                      onChange={handleChange}
                      required
                    />
                    <ValidationError prefix="Referred By" field="referredBy" errors={state.errors} />
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-2" controlId="exampleForm.ControlTextarea1">
                <Form.Label className='fullname'>Please describe what you would like to achieve</Form.Label>
                <Form.Control
                  as="textarea"
                  name="message"
                  rows={3}
                  placeholder="I would like to discuss about....."
                  className='input_box_size no-border'
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
                <ValidationError prefix="Message" field="message" errors={state.errors} />
              </Form.Group>
              <button type="submit" disabled={state.submitting} className="submit_btn">
              Get A Call Back
              </button>
              {submitted && <p className="thank-you-message">Thanks for joining!</p>}
            </Form>
          </div>
          <div className='col-md-4 col-lg-5 col-xl-4 col-12 col-sm-12 main-right-react-div'>
            <div className='reach-out-div'>
              <h4>Reach out to Us at:</h4>
            </div>
           <div className='row mb-2'>
            <div className='col-md-4 col-4 col-lg-4 col-sm-6'>
              <img src={ic} alt="image" />
            </div>
            <div className='col-md-8 col-8 col-lg-8 col-sm-6'>
              <div className='address'>
                <p>101, GM Castle, Friends Colony, Manikonda, Hyderabad, 500089</p>
              </div>
            </div>
           </div>
           <div className='row mb-2'>
            <div className='col-md-4 col-4 col-lg-4 col-sm-4'>
            <img src={ico} alt="image" />
            </div>
            <div className='col-md-4 col-8 col-lg-8 col-sm-8'>
            <div className='email-div'>
                <p>hcubestudio.int@gmail.com</p>
              </div>
            </div>
           </div>
           <div className='row mb-2'>
            <div className='col-md-4 col-4 col-lg-4 col-sm-6'>
            <img src={icon} alt="image" />
            </div>
            <div className='col-md-8 col-8 col-lg-8 col-sm-6'>
            <div className='contact-div'>
                <p>+91 9703990700</p>
              </div>
            </div>
           </div>
           <div className='row'>
           <div className='find_us_text'>
                <h4>Find Us At:</h4>
              </div>
            <div className='col-md-4 col-6 col-lg-4 col-sm-6'>
             
              <div className='find_us_social_icons'>
              <img src={insta} alt="image"  className='insta'/>
            <img src={face} alt="image"  className='face'/>
              </div>
           
            </div>
            <div className='col-md-6 col-6 col-lg-6 col-sm-6'>
           
            </div>
           </div>
          </div>
        </div>
      </div>
      <Spacing lg="130" md="180" />
      {/* <Spacing lg="0" md="180" /> */}
      {/* <Spacing lg="0" md="180" /> */}
      <Footer />
    </>
  );
}