import React from 'react';
import './design.css'
import work from '../../assets/work-1.png'
import Spacing from '../Spacing';
import deone from '../../assets/11.png'
// import detwo from '../../assets/design-11.png';
import deth from '../../assets/12.png'
// import defr from '../../assets/design-12.png';



function Designs() {
    return (
        <>
            <div className='how_we_work_main_parent'>
                <div className='container '>
                    <div className='row'>
                        <div className='Section-Title-work'>
                            <Spacing lg="100" md="20" />
                            <h1> How We Work</h1>
                            <Spacing lg="40" md="20" />

                        </div>
                    </div>
                </div>

                <div className=''>

                    <div className='parent-project-div'>
                        <div className='work-right-img '>
                            <img src={work} alt="" />
                        </div>
                        <div className=' bg-rect'>
                            <div className='container  '>
                                <div className='row '>

                                    <div className='col-md-12 col-xl-4 col-lg-4'>
                                        <div className='cards-project'>
                                            <span>
                                                01
                                            </span>
                                            <h4 class="Statement-of-the-problem">
                                              
                                                Concept <br></br>Development
                                            </h4>
                                            <p className='text-of-the-printing'>
                                            Design mood boards to visually communicate the proposed aesthetic direction.
                                            </p>

                                        </div>

                                    </div>
                                    <div className='col-md-12 col-xl-4 col-lg-4'>
                                        <div className='cards-project'>
                                            <span>
                                                02
                                            </span>
                                            <h4 class="Project-presentation">
                                            Space Planning
                                            </h4>
                                            <p className='text-of-the-printing'>
                                            Create floor plans and furniture layouts to optimize the use of space
                                            </p>


                                        </div>

                                    </div>
                                    <div className='col-md-12 col-xl-4 col-lg-4'>
                                        <div className='cards-project'>
                                            <span>
                                                03
                                            </span>
                                            <h4 class="Development-of-renderings">
                                            ⁠Presentation
                                            </h4>
                                            <p className='text-of-the-printing'>
                                            Provide design concept through visual renderings, 3D models, and material samples
                                            </p>

                                        </div>

                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                <section className='unique_design_vertic'>
                    <div className='vertcale_design_1'></div>
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="100" />
                    {/* <Spacing lg="0" md="100" />
                    <Spacing lg="0" md="50" />
                    <Spacing lg="0" md="20" /> */}
                    <div >

                        <div className='container '>
                            <div className='row'>
                                <div className='Section-Title-de'>
                                    <h1>We will make Unique
                                        Designs for you.</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* < div className='design_parent'>
                    <div className='design_left'>
                        <img src={deone} alt="" className='left-one' />
                        <img src={detwo} alt="" className='left-tw' />
                    </div>
                    <div className='design_right'>
                        <img src={deth} alt="" className='right-one' />
                        <img src={defr} alt="" className='right-tw' />
                    </div>
                </div> */}
                    <div className='container-fluid design_parent'>
                        <div className='row'>
                            <div className='col-md-6 col-lg-6 col-xl-6 col-12 col-sm-12'>
                                <img src={deone} alt="" className='left-one' />
                            </div>
                            <div className='col-md-6 col-lg-6 col-xl-6 col-12 col-sm-12'>
                                <img src={deth} alt="" className='right-one' />
                            </div>
                        </div>
                    </div>


                </section>
                <div className='vertcale_design_child'>
                <div className='vertcale_design_3'></div>
                <div className='vertcale_design_4'></div>
                <div className='container'>
                    <div className='row'>
                        <div className='we_create_design_text'>
                            <p>By staying abreast of the latest trends, technologies, and materials, we infuse our projects with cutting-edge design elements that set them apart.</p>

                        </div>
                    </div>
                </div>
                </div>
            </div>
            <Spacing lg="0" md="60" />
            <div className='border_modern_interior'>

            </div>

        </>
    )
}

export default Designs
