import React from 'react';
import './App.css';
// import Header from './components/pages/Header';
// import Working from './components/pages/working';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Designs from './components/pages/Designs';
import Header from './components/pages/Header';
import ServicePage from './components/pages/servicePage';
import Home from './components/pages/Home';
import About from './components/pages/about';
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Contact from './components/pages/contact';

const App = () => {
  return (
    <>
      
      <BrowserRouter>

<Routes>

  <Route path='/' element={<Home/>}/>
  <Route path='/servicePage' element={<ServicePage/>}/>
  <Route path='/about' element={<About/>}/>
  <Route path='/Contact' element={<Contact/>}/>

</Routes>

</BrowserRouter>
    </>
  )
}

export default App
