// import React from 'react'
// import './create.css';
// import one from "../../assets/1.png";
// import two from "../../assets/2.png";
// import three from "../../assets/3.png";
// import arrowwork from '../../assets/4.svg';
// import Spacing from '../Spacing';



// export default function CreateSpace() {
//     return (
//         <>
//             <div className='create_your_dreame_space_parent'>
//                 <siv className ="vertical_line_create_1"></siv>
//                 <siv className ="vertical_line_create_2"></siv>
//                 <div className='container'>
//                     <div className='row'>
//                         <div className='create_your_dreame_space_content'>
//                             <h1>  Create your </h1>

//                             <h1>Dream Space.</h1>

//                         </div>
//                         <div className='col-md-6 col-lg-4 col-xl-4 col-12 col-sm-12'>
//                             <div className='create_your_dreame_space_content_inner'>
//                                 <img src={one} alt="" />
//                                 <h4>Smooth workflow</h4>
//                                 <p>We deliver the final work
//                                     with great professional
//                                     way.</p>

//                                 <div className='Dream_arrow'>
//                                     <img src={arrowwork} alt="" />
//                                 </div>
//                             </div>

//                         </div>
//                         <div className='col-md-6 col-lg-4 col-xl-4 col-12 col-sm-12'>
//                             <div className='create_your_dreame_space_content_inner'>
//                                 <img src={two} alt="" />
//                                 <h4>Professionalism</h4>
//                                 <p>We deliver the final work
//                                     with great professional
//                                     way.</p>
//                                 <div className='Dream_arrow'>
//                                     <img src={arrowwork} alt="" />
//                                 </div>
//                             </div>

//                         </div>
//                         <div className='col-md-6 col-lg-4 col-xl-4 col-12 col-sm-12'>
//                             <div className='create_your_dreame_space_content_inner'>
//                                 <img src={three} alt="" />
//                                 <h4>Trusted platform</h4>
//                                 <p>We deliver the final work
//                                     with great professional
//                                     way.</p>
//                                 <div className='Dream_arrow'>
//                                     <img src={arrowwork} alt="" />
//                                 </div>
//                             </div>

//                         </div>

//                     </div>
//                 </div>
//                 <Spacing lg="80" md="120" />
//                 <div className='create_your_dreame_space_border_down'>

//                 </div>

//             </div>



//         </>
//     )
// }

import { useState, useEffect, useRef } from 'react';
import './create.css';
import one from "../../assets/1.png";
import two from "../../assets/2.png";
import three from "../../assets/3.png";
import arrowwork from '../../assets/4.svg';
import Spacing from '../Spacing';



export default function CreateSpace() {
    const [animateCards, setAnimateCards] = useState({
        card1: '',
        card2: '',
        card3: '',
    });

    const containerRef = useRef(null);

    const resetAnimations = () => {
        setAnimateCards({
            card1: '',
            card2: '',
            card3: '',
        });

        // Trigger animations again
        setTimeout(() => setAnimateCards(prev => ({ ...prev, card1: 'animate-card-1' })), 200);
        setTimeout(() => setAnimateCards(prev => ({ ...prev, card2: 'animate-card-2' })), 400);
        setTimeout(() => setAnimateCards(prev => ({ ...prev, card3: 'animate-card-3' })), 600);
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        resetAnimations();
                    }
                });
            },
            { threshold: 0.1 } // Trigger when 10% of the section is visible
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, []);

    return (
        <div ref={containerRef} className='create_your_dreame_space_parent'>
            <div className="vertical_line_create_1"></div>
            <div className="vertical_line_create_2"></div>
            <div className='container'>
                <div className='row'>
                    <div className='create_your_dreame_space_content'>
                        <h1>Create your</h1>
                        <h1>Dream Space.</h1>
                    </div>

                    {/* Card 1 */}
                    <div className={`col-md-6 col-lg-4 col-xl-4 col-12 col-sm-12 custom-col ${animateCards.card1}`}>
                        <div className='create_your_dreame_space_content_inner'>
                            <img src={one} alt="Smooth workflow" />
                            <h4>Smooth workflow</h4>
                            <p>We deliver the final work with great professional way.</p>
                            <div className='Dream_arrow'>
                                <img src={arrowwork} alt="arrow" />
                            </div>
                        </div>
                    </div>

                    {/* Card 2 */}
                    <div className={`col-md-6 col-lg-4 col-xl-4 col-12 col-sm-12 custom-col ${animateCards.card2}`}>
                        <div className='create_your_dreame_space_content_inner'>
                            <img src={two} alt="Professionalism" />
                            <h4>Professionalism</h4>
                            <p>We deliver the final work with great professional way.</p>
                            <div className='Dream_arrow'>
                                <img src={arrowwork} alt="arrow" />
                            </div>
                        </div>
                    </div>

                    {/* Card 3 */}
                    <div className={`col-md-6 col-lg-4 col-xl-4 col-12 col-sm-12 custom-col ${animateCards.card3}`}>
                        <div className='create_your_dreame_space_content_inner'>
                            <img src={three} alt="Trusted platform" />
                            <h4>Trusted platform</h4>
                            <p>We deliver the final work with great professional way.</p>
                            <div className='Dream_arrow'>
                                <img src={arrowwork} alt="arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Spacing lg="80" md="120" />
            <div className='create_your_dreame_space_border_down'></div>
        </div>
    );
}
