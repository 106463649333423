import React from 'react';
import './provide.css';
import Spacing from '../Spacing';
import provideone from '../../assets/10.png';
import arrow from '../../assets/4.svg';

const Provide = () => {
    return (
        <>
         <div className='provide_vertical_parent_line'>
        <div className='vertical_line_provide_1'></div>
        <div className='vertical_line_provide_2'></div>
        <div className='vertical_line_provide_3'></div>
            <div className='Provide_parent'>
                <div className='container'>
                    <div className='Provide_content_1'>
                        <h1>Providing Innovative
                            Interior Design.</h1>

                    </div>



                </div>




            </div>
            <div className='provide-images-parent'>
                <img src={provideone} alt="image" className='parent_images' />

                <Spacing lg="70" md="0" />
                <div className='provide-images-parent_content_inner'>
                    <p>We embrace innovation and creativity, constantly pushing the boundaries of design to deliver fresh and innovative solutions to our clients.</p>
                    <div className='Abouts_btn'>
                        <button>About Us</button>
                        <img src={arrow} alt="image" />
                    </div>
                </div>
            </div>
            <Spacing lg="70" md="0" />
            <div className='create_your_dreame_space_border_down'>

            </div>
            </div>
        </>
    )
}

export default Provide;
