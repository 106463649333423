import React from "react";
import "./footer.css";
import footerlogo from '../../assets/logo.png';
import { NavLink } from "react-router-dom"; // Import NavLink from react-router-dom
import Spacing from '../Spacing';

function Footer() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          {/* Column1 */}
          <div className="col">
            <img src={footerlogo} alt="image" />
            <h1 className="list-unstyled">
              <li>H-Cube Studio is an acclaimed multidisciplinary studio specializing in interior architecture, interior design</li>
            </h1>
          </div>
          {/* Column2 */}
          <div className="col middle-footer pt-2">
            <h4>Company</h4>
            <ui className="list-unstyled-1">
            {/* <li>Home</li> */}
              {/* <li>About Us</li> */}
              <li>
                <NavLink 
                  to="/" 
                  className=" footer_links_bottom_custom"
                  onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicking
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink 
                  to="/About" 
                  className="footer_links_bottom_custom"
                  onClick={() => window.scrollTo(0, 0)} 
                >
                  About
                </NavLink>
              </li>
              <li> <NavLink 
                  to="/Contact" 
                  className="footer_links_bottom_custom"
                  onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicking
                >
                  Contact
                </NavLink></li>
            
             
            </ui>
          </div>
          {/* Column3 */}
          <div className="col last-footer pt-3">
            <ui className="list-unstyled">
              {/* <Spacing lg="150" md="0" /> */}
              <Spacing lg="100" md="0" />
              <li><span>Privacy Policy</span> <p>Terms & Conditions</p></li>
              <li>© Copyright 2024 Interiorfirm ― All Rights Reserved.</li>
            </ui>
          </div>
          {/* Column4 */}

        </div>
        <hr />
        <div className="row">
          <p className="col-sm">
            {/* Some additional content... */}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
